import React, { useState, useEffect, useRef } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { makeStyles } from '@mui/styles';
import { useLocation, useNavigate, useParams } from 'react-router';
import domToImage from 'dom-to-image';
import { pdf, Document, Page, Image, StyleSheet } from '@react-pdf/renderer';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTheme } from '@mui/material/styles';
import { FaRegEdit } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
import { BiSolidSave } from 'react-icons/bi';
import { Editor } from '@tinymce/tinymce-react';
import useScriptRef from 'hooks/useScriptRef';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { saveAs } from 'file-saver';

// Components
import '../ICYMI-II/style.css';
import Axios from 'api/Axios';
import Loading from 'components/Loading';
import Message from 'components/Snackbar/Snackbar';
import { API } from 'api/API';
import Popup from 'components/Popup';
import images from 'assets/images/Images';
import { Keys } from 'api/Keys';
import { fontStyles, getFontStyles } from 'constants/fonts';
import { isWindows } from 'react-device-detect';
import { getOriginalHeightByTemplateName } from '../../WallboardHeights';

const ICYMI_II_cartoon = ({ ...others }) => {
  const apiKey = Keys.EditorKey;
  const GetItem = localStorage.getItem('Profile_Details');
  const Details = JSON.parse(GetItem);
  const location = useLocation();
  const [edit, setEdit] = useState({ show: false, approveDisabled: false });
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [secondcol, setSecondCol] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);

  const [popupOpen, setPopupOpen] = useState(false);
  const theme = useTheme();

  const [value, setValue] = useState({
    titleC5: '',
    bodyC5: '',
    author_nameC5: '',
    titleC6: '',
    bodyC6: '',
    author_nameC6: '',
    titleC7: '',
    bodyC7: '',
    author_nameC7: '',
    bodyC8: '',
    titleC8: '',
    author_nameC8: '',
    color: '',
    wallboard_name: '',
    parent_template_name: '',
    photo_caption_c5:'', photo_caption_c6:'', photo_caption_c7:'', photo_caption_c8:'',
  });
  const [data, setData] = useState();
  const [prevalue, setPrevalue] = useState();
  const [approved, setApproved] = useState(false);
  const scriptedRef = useScriptRef();
  const pdfContainerRef = useRef(null);
  const second_col = useRef(null)
  const firstCol = useRef(null);
  const secondCol = useRef(null);
  const [isformEditted, setFormEditted] = useState({ id: '', status: false });

  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    // severity: "",
  });

  const navigate = useNavigate();

  const useStyles = makeStyles(theme => ({
    p: {
      fontFamily: 'AGaramond-Regular',
      lineHeight: 1.2,
      fontSize: '15px',
      textAlign: 'justify',
      wordSpacing: '0.4px',
      letterSpacing: '-0.5px',
      margin: 0,
    },
    h3: {
      fontFamily: 'Korolev',
      fontSize: '20px',
      lineHeight: 1.2,
      // textTransform: "capitalize !important",
      margin: 0,
      textAlign: 'justify',
      letterSpacing: '-0.5px',
    },
    img: {
      width: '100%',
    },
  }));

  const classes = useStyles();
  const params = useParams();

  const handleCloseSnackbar = () => {
    setSnackbar({
      open: false,
      message: '',
      severity: snackbar.severity,
    });
  };

  const removeHtmlTags = htmlString => {
    // Use a browser-based DOM parser to handle HTML parsing
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');

    // Initialize an empty string to store the final result
    let result = '';

    // Recursive function to process each node and its children
    const processNode = node => {
      if (node.nodeType === Node.ELEMENT_NODE) {
        const nodeName = node.nodeName.toLowerCase();

        if (nodeName === 'b' || nodeName === 'strong') {
          result += `<b>${node.textContent}</b>`;
        } else if (nodeName === 'i' || nodeName === 'em') {
          result += `<${nodeName}>`;
          node.childNodes.forEach(child => processNode(child));
          result += `</${nodeName}>`;
        } else if (nodeName === 'a') {
          node.childNodes.forEach(child => processNode(child));
        } else {
          result += node.outerHTML;
        }
      } else if (node.nodeType === Node.TEXT_NODE) {
        // result += node.textContent;
        result += node.textContent.replace(/&gt;/g, '>').replace(/&lt;/g, '<').replace(/&amp;/g, '&').replace(/%([a-zA-Z])/g, '\uFF05$1');
      }
    };

    // Iterate over each child node of the parsed HTML document's body
    doc.body.childNodes.forEach(node => processNode(node));

    // Return the final result string with the processed HTML content
    return result;
  };

  const getData = async gettingID => {
    const id = params.id;
    try {
      setIsLoading(true);
      const response = await Axios.post(API.Get_Single_Version, {
        id: gettingID || id,
      });
      if (response.status === 200) {
        // console.log(response.data.versiondetail, "Hello I am Response");
        setIsLoading(false);
        const data = response?.data?.versiondetail;

        const values = {
          author_nameC5: data?.c5?.author_name,
          author_nameC6: data?.c6?.author_name,
          author_nameC7: data?.c7?.author_name,
          author_nameC8: data?.c8?.author_name,
          bodyC5:
            response?.data?.is_edit === true ? data?.c5?.body : removeHtmlTags(data?.c5?.body),
          bodyC6: response?.data?.is_edit === true ? data?.c6.body : removeHtmlTags(data?.c6?.body),
          bodyC7:
            response?.data?.is_edit === true ? data?.c7?.body : removeHtmlTags(data?.c7?.body),
          bodyC8:
            response?.data?.is_edit === true ? data?.c8?.body : removeHtmlTags(data?.c8?.body),
          titleC5:
            response.data.is_edit === true ? data?.c5?.title : `<h3>${data?.c5?.title}</h3>` || '',
          titleC6:
            response.data.is_edit === true ? data?.c6?.title : `<h3>${data?.c6?.title}</h3>` || '',
          titleC7:
            response.data.is_edit === true ? data?.c7?.title : `<h3>${data?.c7?.title}</h3>` || '',
          titleC8:
            response.data.is_edit === true ? data?.c8?.title : `<h3>${data?.c8?.title}</h3>` || '',
          color: response?.data.template_theme_name,
          wallboard_name: response?.data?.wallboard_name,
          parent_template_name: response?.data?.parent_template_name,
          photo_caption_c5: data?.c5?.photo_credit,
          photo_caption_c6: data?.c6?.photo_credit,
          photo_caption_c7: data?.c7?.photo_credit,
          photo_caption_c8: data?.c8?.photo_credit,
        };
        setValue(values);
        setPrevalue(values);
        setData(response.data);
        return values;
      }
    } catch (err) {
      console.log(err, 'Error while Getting Data');
      setIsLoading(false);
      setSnackbar({ open: true, message: err.message, severity: 'error' });
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setEdit({ ...edit, approveDisabled: false, show: false });
    const payload = {
      docId: params.id,
      template_theme_name: value.color,
      parent_template_name: value?.parent_template_name,
      columnUpdates: [
        {
          columnId: 'c5',
          updatedData: {
            author_img: data?.versiondetail?.c5?.author_img,
            author_name: value.author_nameC5,
            title: value.titleC5,
            body: value.bodyC5,
            photo_credit: value.photo_caption_c5
          },
        },
        {
          columnId: 'c6',
          updatedData: {
            author_img: data?.versiondetail?.c6?.author_img,
            author_name: value.author_nameC6,
            title: value.titleC6,
            body: value.bodyC6,
            photo_credit: value.photo_caption_c6
          },
        },
        {
          columnId: 'c7',
          updatedData: {
            author_img: data?.versiondetail?.c7?.author_img,
            author_name: value.author_nameC7,
            title: value.titleC7,
            body: value.bodyC7,
            photo_credit: value.photo_caption_c7
          },
        },
        {
          columnId: 'c8',
          updatedData: {
            author_img: data?.versiondetail?.c8?.author_img,
            author_name: value.author_nameC8,
            title: value.titleC8,
            body: value.bodyC8,
            photo_credit: value.photo_caption_c8
          },
        },
      ],
    };
    try {
      setIsLoading(true);
      const response = await Axios.post(API.Wallboard_Update, payload);
      if (response.status === 200) {
        setEdit({ show: false });
        setSnackbar({
          open: true,
          severity: 'success',
          message: response.data.msg,
        });
        navigate(`/selectwallboard/icymi-2-cartoon/${response?.data.updatedVersionDetails.id}`);
        await getData(response?.data.updatedVersionDetails.id);
        await runExpandWords(1000);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err, 'Error while Update');
      setIsLoading(false);
      if (err) {
        setSnackbar({
          open: true,
          severity: 'error',
          message: err.response.data.error,
        });
      }
    }
  };

  const ApprovalStatus = async (approval_status, message) => {
    try {
      if (edit.approveDisabled === true) {
        setSnackbar({
          open: true,
          severity: 'warning',
          message: 'Please save the wallboard before proceeding.',
        });
      } else {
        setApproved(true);
        // const height = pdfContainerRef.current.clientHeight;
        // console.log(height, 'hello')
        const id = isformEditted.id || params.id;
        // PDF Blob Generating here
        await runExpandWords(1000);
        const pdfBlob = approval_status === 2 ? null : await generatePDF();
        setIsLoading(true);
        const formData = new FormData();
        formData.append('id', id);
        formData.append('approvalStatus', approval_status ? approval_status.toString() : '');
        if (message !== undefined) {
          formData.append('reason', message);
        }
        if (approval_status !== 2) {
            formData.append('pdfFile', pdfBlob, 'generated.pdf');
        }

        const result = await Axios.Filepost(API.Approve_and_Disapprove_Wallboard, formData);
        if (result.status === 200) {
          setIsLoading(false);
          // setApproved(false);
          setSnackbar({
            open: true,
            message: result.data.message,
            severity: 'success',
          });
          setPopupOpen(false);
          setTimeout(() => {
            navigate('/selectwallboard/pending');
          }, 3000);
        }
      }
    } catch (err) {
      setIsLoading(false);
      console.error(err, 'This is Error');
      setApproved(false);
      setSnackbar({
        open: true,
        message: err.response?.data?.error || 'An error occurred',
        severity: 'error',
      });
      if (window.innerWidth !== window.screen.availWidth) {
        const node = document.getElementById('pdfContainer');
        node.style.border = '1px solid black';
        setSnackbar({
          open: true,
          severity: 'error',
          message: 'Please adjust your screen size to 100% of the browser window. Try Ctrl + 0 on Windows and Cmd + 0 on Mac.',
        });
      }
    }
  };

  const handleApprove = async () => {
    ApprovalStatus(1);
  };

  const handleDisApprove = () => {
    setPopupOpen(true);
  };

var scale = 4;
const generatePDF = async () => {
  const containerWidth = pdfContainerRef?.current?.clientWidth;
  const containerHeight = pdfContainerRef.current.clientHeight - 12;
  const node = document.getElementById('pdfContainer');
  const isEdge = /Edg\//.test(navigator.userAgent);
  const isFirefox = /Firefox/.test(navigator.userAgent);
  const zoom = ((window.outerWidth - 10) / window.innerWidth) * 100;

  const FullScreenCheck =
    isEdge === true
      ? zoom >= 98
      : isFirefox === true
        ? window.devicePixelRatio === 1
        : window.innerWidth === window.screen.availWidth;
  try {
    if (FullScreenCheck === true) {
      await runExpandWords(1000);
      const dataUrl = await domToImage.toPng(node, {
        allowTaint: true,
        useCORS: true,
        scale: 10,
        width: node.clientWidth * scale,
        height: node.clientHeight * scale,
        style: {
          transform: 'scale(' + scale + ')',
          transformOrigin: 'top left',
        },
      });
      // Convert base64 to ArrayBuffer
      const byteCharacters = atob(dataUrl.split(',')[1]);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'image/jpeg' });

      // Create a URL for the Blob
      const url = URL.createObjectURL(blob);

      URL.revokeObjectURL(url);
      const MyDocument = () => (
        <Document>
          <Page
            size={{ width: containerWidth, height: containerHeight }}
          >
            <Image src={dataUrl} style={{ width: containerWidth, height: containerHeight }} />
          </Page>
        </Document>
      );

      const blob1 = await pdf(<MyDocument />).toBlob(); 
      saveAs(blob1, `${value.wallboard_name}.pdf`);
      await runExpandWords(1000);
      return blob1;
    }
  } catch (error) {
    console.error('Error generating image:', error);
  }
};


  const delay = ms =>
    new Promise(resolve => {
      setTimeout(resolve, ms);
    });

  const applyStyles = (element, fontSize, wordSpacing, letterSpacing, lineHeight) => {
    element.style.setProperty('font-size', `${fontSize}px`, 'important');
    element.style.setProperty('word-spacing', `${wordSpacing}px`, 'important');
    element.style.setProperty('letter-spacing', `${letterSpacing}px`, 'important');
    element.style.setProperty('line-height', `${lineHeight}`, 'important');
  };

  const ExpandWords = async () => {
    const ContentHeight = firstCol?.current?.clientHeight;

    const WallboardHeight = data.parent_template_name === '2-ICYMI-CARTOON' ||
            data.parent_template_name === '2-COL-ICYMI-CART'
          ? 1505.5
          : '';

    if (ContentHeight >= WallboardHeight) {
      return;
    } else {
      requestAnimationFrame(() => {
        const dvrslt = document.querySelector('.first_col1 .auth-details div');
        const result = document.querySelector('#titles1 p');
        const result1 = document.querySelector('#titles2 p');
        const Checkdata = result && result1;

        if (Checkdata) {
          let fontSize = 14.5; 
          let wordSpacing = 0; 
          let letterSpacing = -0.5;
          let lineHeight = 1.2; 

          let container_height = dvrslt.clientHeight; 

          while (container_height < WallboardHeight) {
            fontSize += 0.01;
            wordSpacing += 0.01;
            letterSpacing += 0.001;
            lineHeight += 0.01;

            // Apply the new styles to both elements
            applyStyles(result, fontSize, wordSpacing, letterSpacing, lineHeight);
            applyStyles(result1, fontSize, wordSpacing, letterSpacing, lineHeight);

            // Recalculate the container height after applying the styles
            container_height = dvrslt.clientHeight;

            // Exit if the container height exceeds or equals 1563
            if (container_height >= WallboardHeight) {
              break;
            }
          }
        }
      });
    }
  };

  const ExpandWordsSecondCol = async () => {
    let ContentHeight = secondCol?.current?.clientHeight;
    const WallboardHeight = data.parent_template_name === '2-ICYMI-CARTOON' ||
            data.parent_template_name === '2-COL-ICYMI-CART'
          ? 1520
          : '';
    if (ContentHeight >= WallboardHeight) {
      return;
    } else {
      requestAnimationFrame(() => {
        const dvrsltz = document.querySelector('#second_col .auth-details div');
        const result = document.querySelector('#bodyC7 p');
        const result1 = document.querySelector('#bodyC8 p');
        const Checkdata = result && result1;
        if (Checkdata) {
          let fontSize = 14.5; 
          let wordSpacing = 0; 
          let letterSpacing = -0.5;
          let lineHeight = 1.2; 
          let container_heightz = dvrsltz.clientHeight; 
          while (container_heightz < WallboardHeight) {
            // Increment the font size, word spacing, letter spacing, and line height
            fontSize += 0.01;
            wordSpacing += 0.01;
            letterSpacing += 0.01;
            lineHeight += 0.01;
            // Apply the new styles to both elements
            applyStyles(result, fontSize, wordSpacing, letterSpacing, lineHeight);
            applyStyles(result1, fontSize, wordSpacing, letterSpacing, lineHeight);
            container_heightz = dvrsltz.clientHeight;
            if (container_heightz >= WallboardHeight) {
              break;
            }
          }
        }
      });
    }
  };

  const runExpandWords = async time => {
    await delay(time); 
    await ExpandWords();
    await delay(time); 
    await ExpandWordsSecondCol();
  };

  const FetchAllData = async () => {
    const data = await getData();
    setIsLoading1(true);
    await delay(1000); 
    const ContentHeight = firstCol?.current?.clientHeight;
    const WallboardHeight = 1504;
    if (ContentHeight >= WallboardHeight) {
      setIsLoading1(false);
      return;
    } else {
      setIsLoading1(false);
      setIsDataLoaded(true);
    }
    const CheckSecondCol = secondCol?.current?.clientHeight;
    if (CheckSecondCol >= WallboardHeight) {
      setIsLoading1(false);
      return;
    } else {
      setIsLoading1(false);
      setSecondCol(true);
    }
  };

  const Wallboard_Approval_Status = async (approval_status, ScreenType) => {
    const message = 'Disapprove';
    try {
      const id = params.id;
      setIsLoading(true);
      const formData = new FormData();
      formData.append('id', id);
      formData.append('approvalStatus', approval_status.toString());
      if (approval_status == 2) {
        formData.append('reason', message);
      }
      const result = await Axios.Filepost(API.Approve_and_Disapprove_Wallboard, formData);
      if (result.status === 200) {
        setIsLoading(false);
        setSnackbar({
          open: true,
          message: result.data.message,
          severity: 'success',
        });
        setTimeout(() => {
          navigate(
            ScreenType === 'Reject'
              ? '/selectwallboard/rejected'
              : ScreenType === 'Pending' && '/selectwallboard/pending'
          );
        }, 3000);
      }
    } catch (err) {
      setIsLoading(false);
      console.error(err, 'This is Error');
      setSnackbar({
        open: true,
        message: err.response?.data?.error || 'An error occurred',
        severity: 'error',
      });
    }
  };

  const handlePending = async () => {
    await Wallboard_Approval_Status(0, 'Pending');
  };

  useEffect(() => {
    FetchAllData();
  }, []);

  useEffect(() => {
    if (isDataLoaded) {
      delay(1000).then(() => {
        ExpandWords();
      });
    }
  }, [isDataLoaded]);

  useEffect(() => {
    if (secondcol) {
      delay(1500).then(() => {
        ExpandWordsSecondCol();
      });
    }
  }, [secondcol]);

  return (
    <MainCard
      title={data?.wallboard_name}
      className="showVersions"
      {...(Details.role !== "3" &&
        (data?.approval_status === 0 || data?.approval_status === 2 || location?.state?.reject === 'yes') && {
          approve: handleApprove,
          buttontitle1: "Approve",
          approveColor: "#00C853",
          disapprove: data?.approval_status === 2 ? handlePending: handleDisApprove,
          buttontitle2: data?.approval_status === 2 ?"Pending": "Reject",
          disapprovecolor: data?.approval_status === 2 ? "#15223f": "#F44336",
        })}
    >
      <Popup
        style={{ height: '600px' }}
        open={popupOpen}
        // onClose={handlePopupClose}
        title="Please enter the reason for rejection"
        content={
          <>
            <Formik
              initialValues={{
                message: '',
              }}
              validationSchema={Yup.object().shape({
                message: Yup.string().required('Message is required'),
              })}
              onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                  if (values.message !== null || undefined) {
                    ApprovalStatus(2, values.message);
                  }
                  if (scriptedRef.current) {
                    setStatus({ success: true });
                    setSubmitting(false);
                  }
                } catch (err) {
                  console.error(err);
                  if (scriptedRef.current) {
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                  }
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form
                  className={classes.forms}
                  noValidate
                  onSubmit={handleSubmit}
                  {...others}
                  autoComplete="off"
                >
                  {/* Email */}
                  <FormControl
                    fullWidth
                    error={Boolean(touched.message && errors.message)}
                    sx={{ ...theme.typography.customInput }}
                  >
                    <InputLabel htmlFor="outlined-adornment-message">Reason</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-email"
                      type="text"
                      value={values.message}
                      name="message"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label="Reason"
                      inputProps={{}}
                    />
                    {touched.message && errors.message && (
                      <FormHelperText error id="standard-weight-helper-text-email-login">
                        {errors.message}
                      </FormHelperText>
                    )}
                  </FormControl>

                  {errors.submit && (
                    <Box sx={{ mt: 3 }}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Box>
                  )}

                  <Box sx={{ mt: 1 }} style={{ textAlign: 'center' }}>
                    {/* <AnimateButton> */}
                    <Button
                      size="large"
                      variant="contained"
                      color="secondary"
                      style={{ background: '#F44336', marginRight: 10 }}
                      onClick={() => setPopupOpen(false)}
                    >
                      Close
                    </Button>
                    <Button
                      disableElevation
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained"
                      color="secondary"
                      style={{ background: '#15223F' }}
                    >
                      Reject
                    </Button>
                    {/* </AnimateButton> */}
                  </Box>
                </form>
              )}
            </Formik>
          </>
        }
      />
      <div className="main-section">
        <div
          className="col-3"
          style={{
            maxWidth:
              data?.parent_template_name === '2-ICYMI-POLL' ||
              data?.parent_template_name === '2-COL-ICYMI-POLL'
                ? '564px'
                : '564px',
          }}
        >
          {!edit.show && !isLoading && (
            <>
              <div
                style={{
                  textAlign: 'center',
                  display: data?.approval_status === 0 && Details.role !== '3' ? 'block' : 'none',
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  style={{
                    background: '#15223F',
                    padding: '6px 0px 6.1px 10px',
                    minWidth: '0px',
                    marginBottom: '10px',
                  }}
                  startIcon={<FaRegEdit style={{ fontSize: 15 }} />}
                  onClick={async () => {
                    setEdit({ ...edit, show: true, approveDisabled: true });
                    await runExpandWords(1000);
                  }}
                />
              </div>
              <div className='two-whitecols' 
                 ref={pdfContainerRef}
                id="pdfContainer"
              style={{height: getOriginalHeightByTemplateName(value)}}>
              <div
                style={{
                  border: 'unset',
                  backgroundColor:
                    value.color === '#e0a600'
                      ? '#fbf4e8'
                      : value.color === '#a0251c'
                        ? '#f6eae3'
                        : value.color === '#22553f'
                          ? '#e9e9ea'
                          : '',
                  height: getOriginalHeightByTemplateName(value)
                }}
              >
                <div
                  className="first_col1"
                  style={{
                    backgroundColor:
                      value.color === '#e0a600'
                        ? '#fbf4e8'
                        : value.color === '#a0251c'
                          ? '#f6eae3'
                          : value.color === '#22553f'
                            ? '#e9e9ea'
                            : value.color === '#001948'
                              ? '#fff'
                              : '',
                  }}
                >
                  <div className="auth-details">
                    {/* <h3 className={classes.h3}> */}
                    <div ref={firstCol}>
                      <h3 className="editor">
                        <Editor
                          apiKey={apiKey}
                          id="titleC5"
                          disabled
                          value={value.titleC5}
                          init={{
                            menubar: true,
                            inline: true,
                            content_style: `#titleC5  { color:${value && value.color}; }`,
                          }}
                          onEditorChange={(content, editor) => {
                            setValue({ ...value, titleC5: content });
                          }}
                          style={{ color: value.color }}
                        />
                      </h3>
                      {/* </h3> */}
                      {!data?.versiondetail?.c5?.author_img ||
                      !data?.versiondetail?.c5?.dimensions ||
                      data?.versiondetail?.c5?.dimensions?.width !== 269 ||
                      data?.versiondetail?.c5?.dimensions?.height !== 270 ||
                      !data?.versiondetail?.c5.author_name ? null : (
                        <p
                          style={{
                            display:
                              !data?.versiondetail?.c5?.author_img ||
                              !data?.versiondetail?.c5?.dimensions ||
                              data?.versiondetail?.c5?.dimensions?.width !== 269 ||
                              data?.versiondetail?.c5?.dimensions?.height !== 270 ||
                              !data?.versiondetail?.c5.author_name
                                ? 'none'
                                : 'block',
                          }}
                          className={`${classes.p} auth-img`}
                        >
                          <span
                            style={{
                              width: '69px',
                              height: '70px',
                              display: 'block',
                            }}
                          >
                            <img src={data?.versiondetail?.c5?.author_img} alt="" />
                          </span>
                          <span
                            style={{
                              color:
                                value.color === '#e0a600'
                                  ? '#e0a600'
                                  : value.color === '#a0251c'
                                    ? '#a0251c'
                                    : '',
                            }}
                          >
                            <Editor
                              apiKey={apiKey}
                              id="author_nameC5"
                              disabled
                              value={value?.author_nameC5}
                              init={{
                                menubar: true,
                                inline: true,
                                content_style: `#author_nameC5 p { color: ${
                                  value && value.color
                                }; font-size: 12px !important;}`,
                              }}
                              onEditorChange={(content, editor) => {
                                setValue({ ...value, author_nameC5: content });
                              }}
                              style={{ color: value.color }}
                            />
                            {(value.photo_caption_c5) && (<><span style={{ color: data && data.color }} className='photo-credit'>Image:</span>
                              <Editor
                                apiKey={apiKey}
                                id="caption_c5"
                                value={value.photo_caption_c5}
                                disabled
                                init={{
                                  menubar: true,
                                  inline: true,
                                  readonly:true,
                                  content_style: `#caption_c5 p {font-size: 12px !important;font-family:'AGaramond-Regular';color: ${data && data.color
                                    }; }`,
                                  font_family_formats: getFontStyles(
                                    'Adobe Garamond Pro=AGaramond-Regular;',
                                    'Korolev Condensed Bold=korolev-condensed;'
                                  ),
                                  plugins: 'fontfamily',
                                }}
                                onEditorChange={async (content, editor) => {
                                  setValue({ ...value, photo_caption_c5: content });
                                }}
                              />
                            </>
                            )}
                          </span>
                        </p>
                      )}
                      <p className={classes.p}>
                        <Editor
                          disabled
                          apiKey={apiKey}
                          id="titles1"
                          className="editor-input descript  task"
                          value={value.bodyC5}
                          init={{
                            menubar: true,
                            inline: true,
                          }}
                          onEditorChange={(content, editor) => {
                            setValue({ ...value, bodyC5: content });
                          }}
                        />
                      </p>

                      <div className="second_para-below" style={{ paddingLeft: 0 }}>
                        <h3 className={classes.h3}>
                          <Editor
                            disabled
                            apiKey={apiKey}
                            id="editorTitle3"
                            value={value.titleC6}
                            init={{
                              menubar: true,
                              inline: true,
                              content_style: `#editorTitle3 { color: ${
                                value && value.color
                              }; margin-top:6px; }`,
                            }}
                            onEditorChange={(content, editor) => {
                              setValue({ ...value, titleC6: content });
                            }}
                            style={{ color: value.color }}
                          />
                        </h3>

                        {!data?.versiondetail?.c6?.author_img ||
                        !data?.versiondetail?.c6?.dimensions ||
                        data?.versiondetail?.c6?.dimensions?.width !== 269 ||
                        data?.versiondetail?.c6?.dimensions?.height !== 270 ||
                        !data?.versiondetail?.c6.author_name ? null : (
                          <p
                            style={{
                              display:
                                !data?.versiondetail?.c6?.author_img ||
                                !data?.versiondetail?.c6?.dimensions ||
                                data?.versiondetail?.c6?.dimensions?.width !== 269 ||
                                data?.versiondetail?.c6?.dimensions?.height !== 270 ||
                                !data?.versiondetail?.c6.author_name
                                  ? 'none'
                                  : 'block',
                            }}
                            className={`${classes.p} auth-img`}
                          >
                            <span
                              style={{
                                width: '69px',
                                height: '70px',
                                display: 'block',
                              }}
                            >
                              <img src={data?.versiondetail?.c6?.author_img} alt="" />
                            </span>
                            <span
                              style={{
                                color:
                                  value.color === '#e0a600'
                                    ? '#e0a600'
                                    : value.color === '#a0251c'
                                      ? '#a0251c'
                                      : '',
                              }}
                            >
                              <Editor
                                apiKey={apiKey}
                                id="author_nameC6"
                                value={value?.author_nameC6}
                                disabled
                                init={{
                                  menubar: true,
                                  inline: true,
                                  content_style: `#author_nameC6 p {font-size: 12px !important; color: ${value && value.color};}`,
                                }}
                                onEditorChange={(content, editor) => {
                                  setValue({
                                    ...value,
                                    author_nameC6: content,
                                  });
                                }}
                              />
                              {(value.photo_caption_c6) && (<><span style={{ color: data && data.color }} className='photo-credit'>Image:</span>
                              <Editor
                                apiKey={apiKey}
                                id="caption_c6"
                                value={value.photo_caption_c6}
                                disabled
                                init={{
                                  menubar: true,
                                  inline: true,
                                  readonly:true,
                                  content_style: `#caption_c6 p {font-size: 12px !important;font-family:'korolev-condensed';color: ${data && data.color
                                    }; }`,
                                  font_family_formats: getFontStyles(
                                    'Adobe Garamond Pro=AGaramond-Regular;',
                                    'Korolev Condensed Bold=korolev-condensed;'
                                  ),
                                  plugins: 'fontfamily',
                                }}
                                onEditorChange={async (content, editor) => {
                                  setValue({ ...value, photo_caption_c6: content });
                                  // 
                                }}
                              />
                            </>
                            )}
                            </span>
                          </p>
                        )}
                        <p className={classes.p}>
                          <Editor
                            disabled
                            apiKey={apiKey}
                            id="titles2"
                            className="editor-input descript  task"
                            // className="editorss"
                            value={value.bodyC6}
                            init={{
                              menubar: true,
                              inline: true,
                            }}
                            onEditorChange={(content, editor) => {
                              setValue({ ...value, bodyC6: content });
                            }}
                          />
                          {data?.parent_template_name === '2-ICYMI-CARTOON' ||
                            (data?.parent_template_name === '2-COL-ICYMI-CART' && (
                              <>
                                <div style={{ width: 270, height: 320 }}>
                                  <Divider sx={{ borderColor: 'black' }} />
                                  <img height={348} width={270} src={images.Empty_Space_Cartoon} />
                                </div>
                              </>
                            ))}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                <div
                ref={second_col}
                  className="second_col"
                  id="second_col"
                  style={{
                    backgroundColor:
                      value.color === '#e0a600'
                        ? '#fbf4e8'
                        : value.color === '#a0251c'
                          ? '#f6eae3'
                          : value.color === '#22553f'
                            ? '#e9e9ea'
                            : value.color === '#001948'
                              ? '#fff'
                              : '',
                              height:
                    data?.parent_template_name === '2-ICYMI-POLL' ||
                    data?.parent_template_name === '2-COL-ICYMI-POLL'
                      ? '1170px'
                      : data?.parent_template_name === '2-ICYMI-CARTOON' ||
                          data?.parent_template_name === '2-COL-ICYMI-CART'
                        ? '1563px'
                        : '',
                  }}
                >
                  <div className="auth-details">
                    <div ref={secondCol}>
                      <h3 className={classes.h3}>
                        <Editor
                          disabled
                          apiKey={apiKey}
                          id="editortitleC7"
                          value={value.titleC7}
                          init={{
                            menubar: true,
                            inline: true,
                            content_style: `#editortitleC7 { color: ${value && value.color}; }`,
                          }}
                          onEditorChange={(content, editor) => {
                            setValue({ ...value, titleC7: content });
                          }}
                        />
                      </h3>
                      {!data?.versiondetail?.c7?.author_img ||
                      !data?.versiondetail?.c7?.dimensions ||
                      data?.versiondetail?.c7?.dimensions?.width !== 269 ||
                      data?.versiondetail?.c7?.dimensions?.height !== 270 ||
                      !data?.versiondetail?.c7.author_name ? null : (
                        <p
                          style={{
                            display:
                              !data?.versiondetail?.c7?.author_img ||
                              !data?.versiondetail?.c7?.dimensions ||
                              data?.versiondetail?.c7?.dimensions?.width !== 269 ||
                              data?.versiondetail?.c7?.dimensions?.height !== 270 ||
                              !data?.versiondetail?.c7.author_name
                                ? 'none'
                                : 'block',
                          }}
                          className={`${classes.p} auth-img`}
                        >
                          <span
                            style={{
                              width: '69px',
                              height: '70px',
                              display: 'block',
                            }}
                          >
                            <img src={data?.versiondetail?.c7?.author_img} alt="" />
                          </span>
                          <span
                            style={{
                              color:
                                value.color === '#e0a600'
                                  ? '#e0a600'
                                  : value.color === '#a0251c'
                                    ? '#a0251c'
                                    : '',
                            }}
                          >
                            <Editor
                              apiKey={apiKey}
                              id="author_nameC7"
                              value={value?.author_nameC7}
                              disabled
                              init={{
                                menubar: true,
                                inline: true,
                                content_style: `#author_nameC7 p {font-size: 12px !important; color: ${value && value.color};}`,
                              }}
                              onEditorChange={(content, editor) => {
                                setValue({ ...value, author_nameC7: content });
                              }}
                            />
                            {(value.photo_caption_c7) && (<><span style={{ color: data && data.color }} className='photo-credit'>Image:</span>
                              <Editor
                                apiKey={apiKey}
                                id="caption_c7"
                                value={value.photo_caption_c7}
                                disabled
                                init={{
                                  menubar: true,
                                  inline: true,
                                  readonly:true,
                                  content_style: `#caption_c7 p {font-size: 12px !important;font-family:'korolev-condensed';color: ${data && data.color
                                    }; }`,
                                  font_family_formats: getFontStyles(
                                    'Adobe Garamond Pro=AGaramond-Regular;',
                                    'Korolev Condensed Bold=korolev-condensed;'
                                  ),
                                  plugins: 'fontfamily',
                                }}
                                onEditorChange={async (content, editor) => {
                                  setValue({ ...value, photo_caption_c7: content });
                                }}
                              />
                            </>
                            )}
                          </span>
                        </p>
                      )}
                      <p className={classes.p}>
                        <Editor
                          disabled
                          apiKey={apiKey}
                          id="bodyC7"
                          value={value?.bodyC7}
                          init={{
                            menubar: true,
                            inline: true,
                          }}
                          onEditorChange={(content, editor) => {
                            setValue({ ...value, bodyC7: content });
                          }}
                        />
                      </p>

                      <div className="second_para-below" style={{ paddingLeft: 0 }}>
                        <h3 className={classes.h3}>
                          <Editor
                            disabled
                            apiKey={apiKey}
                            id="titleC8"
                            value={value.titleC8}
                            init={{
                              menubar: true,
                              inline: true,
                              content_style: `#titleC8 { color: ${value && value.color}; }`,
                            }}
                            onEditorChange={(content, editor) => {
                              setValue({ ...value, titleC8: content });
                            }}
                          />
                        </h3>
                        {!data?.versiondetail?.c8?.author_img ||
                        !data?.versiondetail?.c8?.dimensions ||
                        data?.versiondetail?.c8?.dimensions?.width !== 269 ||
                        data?.versiondetail?.c8?.dimensions?.height !== 270 ||
                        !data?.versiondetail?.c8.author_name ? null : (
                          <p
                            style={{
                              display:
                                !data?.versiondetail?.c8?.author_img ||
                                !data?.versiondetail?.c8?.dimensions ||
                                data?.versiondetail?.c8?.dimensions?.width !== 269 ||
                                data?.versiondetail?.c8?.dimensions?.height !== 270 ||
                                !data?.versiondetail?.c8.author_name
                                  ? 'none'
                                  : 'block',
                            }}
                            className={`${classes.p} auth-img`}
                          >
                            <span
                              style={{
                                width: '69px',
                                height: '70px',
                                display: 'block',
                              }}
                            >
                              <img src={data?.versiondetail?.c8?.author_img} alt="" />
                            </span>
                            <span
                              style={{
                                color:
                                  value.color === '#e0a600'
                                    ? '#e0a600'
                                    : value.color === '#a0251c'
                                      ? '#a0251c'
                                      : '',
                              }}
                            >
                              <Editor
                                apiKey={apiKey}
                                id="author_nameC8"
                                value={value?.author_nameC8}
                                disabled
                                init={{
                                  menubar: true,
                                  inline: true,
                                  content_style: `#author_nameC8 p {font-size: 12px !important; color: ${value && value.color};}`,
                                }}
                                onEditorChange={(content, editor) => {
                                  setValue({
                                    ...value,
                                    author_nameC8: content,
                                  });
                                }}
                              />
                              {(value.photo_caption_c8) && (<><span style={{ color: data && data.color }} className='photo-credit'>Image:</span>
                              <Editor
                                apiKey={apiKey}
                                id="caption_c8"
                                value={value.photo_caption_c8}
                                disabled
                                init={{
                                  menubar: true,
                                  inline: true,
                                  readonly:true,
                                  content_style: `#caption_c8 p {font-size: 12px !important;font-family:'korolev-condensed';color: ${data && data.color
                                    }; }`,
                                  font_family_formats: getFontStyles(
                                    'Adobe Garamond Pro=AGaramond-Regular;',
                                    'Korolev Condensed Bold=korolev-condensed;'
                                  ),
                                  plugins: 'fontfamily',
                                }}
                                onEditorChange={async (content, editor) => {
                                  setValue({ ...value, photo_caption_c8: content });
                                }}
                              />
                            </>
                            )}
                            </span>
                          </p>
                        )}
                        <p className={classes.p}>
                          <Editor
                            disabled
                            apiKey={apiKey}
                            id="bodyC8"
                            className="editor-input descript  task"
                            value={value.bodyC8}
                            init={{
                              menubar: true,
                              inline: true,
                            }}
                            onEditorChange={(content, editor) => {
                              setValue({ ...value, bodyC8: content });
                            }}
                          />
                          <span
                            style={{
                              width: '15px',
                              height: '15px',
                              display: 'inline-block',
                            }}
                          >
                            {data && (
                              <img src={images.PW_FAV_ICON} style={{ width: 15, height: 15 }} />
                            )}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              {/* </div> */}
            </>
          )}

          {edit.show && !isLoading && (
            <form onSubmit={handleSubmit}>
              <div
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '10px',
                }}
              >
                <Button
                  size="large"
                  variant="contained"
                  style={{
                    background: '#C62828',
                    fontSize: '20px',
                    padding: '5px',
                    minWidth: '0px',
                    marginBottom: '7.1px',
                  }}
                  onClick={async () => {
                    setValue(prevalue);
                    setEdit({ show: false, approveDisabled: false });
                    await runExpandWords(1000);
                  }}
                >
                  <AiOutlineClose />
                </Button>
                <Button
                  size="large"
                  type="submit"
                  variant="contained"
                  style={{
                    background: '#1d213e',
                    fontSize: '20px',
                    padding: '5px',
                    minWidth: '0px',
                    marginBottom: '7.1px',
                  }}
                >
                  <BiSolidSave />
                </Button>
              </div>
              
              <div className='two-whitecols' style={{height: getOriginalHeightByTemplateName(value)}}>
              <div
                // className="two-whitecols"
                style={{
                  border:'unset',
                  backgroundColor:
                    value.color === '#e0a600'
                      ? '#fbf4e8'
                      : value.color === '#a0251c'
                        ? '#f6eae3'
                        : value.color === '#22553f'
                          ? '#e9e9ea'
                          : '',
                  height: getOriginalHeightByTemplateName(value)
                }}
              >
                <div
                  className="first_col1"
                  style={{
                    backgroundColor:
                      value.color === '#e0a600'
                        ? '#fbf4e8'
                        : value.color === '#a0251c'
                          ? '#f6eae3'
                          : value.color === '#22553f'
                            ? '#e9e9ea'
                            : value.color === '#001948'
                              ? '#fff'
                              : '',
                  }}
                >
                  <div className="auth-details">
                    {/* <h3 className={classes.h3}> */}
                    <div ref={firstCol}>
                      <h3 className="editor">
                        <Editor
                          apiKey={apiKey}
                          id="editorTitle1"
                          value={value.titleC5}
                          init={{
                            menubar: true,
                            inline: true,
                            content_style: `#editorTitle1 { color: ${value && value.color}; }`,
                            font_family_formats: getFontStyles(
                              'Adobe Garamond Pro=AGaramond-Regular;',
                              'Korolev Condensed Bold=korolev-condensed;'
                            ),
                            plugins: 'fontfamily',
                          }}
                          onEditorChange={(content, editor) => {
                            setValue({ ...value, titleC5: content });
                          }}
                          style={{ color: value.color }}
                        />
                      </h3>
                      <p
                        style={{
                          display:
                            !data?.versiondetail?.c5?.author_img ||
                            !data?.versiondetail?.c5?.dimensions ||
                            data?.versiondetail?.c5?.dimensions?.width !== 269 ||
                            data?.versiondetail?.c5?.dimensions?.height !== 270 ||
                            !data?.versiondetail?.c5.author_name
                              ? 'none'
                              : 'block',
                        }}
                        className={`${classes.p} auth-img`}
                      >
                        <span
                          style={{
                            width: '69px',
                            height: '70px',
                            display: 'block',
                          }}
                        >
                          <img src={data?.versiondetail?.c5?.author_img} alt="" />
                        </span>
                        <span
                          style={{
                            color:
                              value.color === '#e0a600'
                                ? '#e0a600'
                                : value.color === '#a0251c'
                                  ? '#a0251c'
                                  : '',
                          }}
                        >
                          <Editor
                            apiKey={apiKey}
                            id="author_nameC5"
                            value={value?.author_nameC5}
                            init={{
                              menubar: true,
                              inline: true,
                              content_style: `#author_nameC5 p {font-size: 12px !important;color: ${value && value.color};}`,
                              font_family_formats: getFontStyles(
                                'Adobe Garamond Pro=AGaramond-Regular;',
                                'Korolev Condensed Bold=korolev-condensed;'
                              ),
                              plugins: 'fontfamily',
                            }}
                            onEditorChange={(content, editor) => {
                              setValue({ ...value, author_nameC5: content });
                            }}
                          />
                          {(value.photo_caption_c5) && (<><span style={{ color: data && data.color }} className='photo-credit'>Image:</span>
                              <Editor
                                apiKey={apiKey}
                                id="caption_c5"
                                value={value.photo_caption_c5}
                                init={{
                                  menubar: true,
                                  inline: true,
                                  content_style: `#caption_c5 p {font-size: 12px !important;font-family:'AGaramond-Regular';color: ${data && data.color
                                    }; }`,
                                  font_family_formats: getFontStyles(
                                    'Adobe Garamond Pro=AGaramond-Regular;',
                                    'Korolev Condensed Bold=korolev-condensed;'
                                  ),
                                  plugins: 'fontfamily',
                                }}
                                onEditorChange={async (content, editor) => {
                                  setValue({ ...value, photo_caption_c5: content });
                                  await runExpandWords(1000);
                                }}
                              />
                            </>
                            )}
                          {/* <strong>{value?.author_nameC5}</strong> */}
                        </span>
                      </p>
                      <p className={classes.p}>
                        <Editor
                          apiKey={apiKey}
                          id="titles1"
                          className="editor-input descript  task"
                          value={value.bodyC5}
                          init={{
                            menubar: true,
                            inline: true,
                            font_family_formats: fontStyles,
                            plugins: 'fontfamily',
                          }}
                          onEditorChange={async (content, editor) => {
                            setValue({ ...value, bodyC5: content });
                            await runExpandWords(1000);
                          }}
                        />
                       
                      </p>

                      <div className="second_para-below" style={{ paddingLeft: 0 }}>
                        <h3 className={classes.h3}>
                          <Editor
                            apiKey={apiKey}
                            id="editorTitle3"
                            value={value.titleC6}
                            init={{
                              menubar: true,
                              inline: true,
                              content_style: `#editorTitle3 { color: ${
                                value && value.color
                              }; margin-top:6px; }`,
                              font_family_formats: getFontStyles(
                                'Adobe Garamond Pro=AGaramond-Regular;',
                                'Korolev Condensed Bold=korolev-condensed;'
                              ),
                              plugins: 'fontfamily',
                            }}
                            onEditorChange={(content, editor) => {
                              setValue({ ...value, titleC6: content });
                            }}
                            style={{ color: value.color }}
                          />
                        </h3>
                        <p
                          style={{
                            display:
                              !data?.versiondetail?.c6?.author_img ||
                              !data?.versiondetail?.c6?.dimensions ||
                              data?.versiondetail?.c6?.dimensions?.width !== 269 ||
                              data?.versiondetail?.c6?.dimensions?.height !== 270 ||
                              !data?.versiondetail?.c6.author_name
                                ? 'none'
                                : 'block',
                          }}
                          className={`${classes.p} auth-img`}
                        >
                          <span
                            style={{
                              width: '69px',
                              height: '70px',
                              display: 'block',
                            }}
                          >
                            <img src={data?.versiondetail?.c6?.author_img} alt="" />
                          </span>
                          <span
                            style={{
                              color:
                                value.color === '#e0a600'
                                  ? '#e0a600'
                                  : value.color === '#a0251c'
                                    ? '#a0251c'
                                    : '',
                            }}
                          >
                            <Editor
                              apiKey={apiKey}
                              id="author_nameC6"
                              value={value?.author_nameC6}
                              init={{
                                menubar: true,
                                inline: true,
                                content_style: `#author_nameC6 p {font-size: 12px !important;color: ${value && value.color};}`,
                                font_family_formats: getFontStyles(
                                  'Adobe Garamond Pro=AGaramond-Regular;',
                                  'Korolev Condensed Bold=korolev-condensed;'
                                ),
                                plugins: 'fontfamily',
                              }}
                              onEditorChange={(content, editor) => {
                                setValue({ ...value, author_nameC6: content });
                              }}
                            />
                            {(value.photo_caption_c6) && (<><span style={{ color: data && data.color }} className='photo-credit'>Image:</span>
                              <Editor
                                apiKey={apiKey}
                                id="caption_c6"
                                value={value.photo_caption_c6}
                                init={{
                                  menubar: true,
                                  inline: true,
                                  content_style: `#caption_c6 p {font-size: 12px !important;font-family:'korolev-condensed';color: ${data && data.color
                                    }; }`,
                                  font_family_formats: getFontStyles(
                                    'Adobe Garamond Pro=AGaramond-Regular;',
                                    'Korolev Condensed Bold=korolev-condensed;'
                                  ),
                                  plugins: 'fontfamily',
                                }}
                                onEditorChange={async (content, editor) => {
                                  setValue({ ...value, photo_caption_c6: content });
                                  await runExpandWords(1000);
                                }}
                              />
                            </>
                            )}
                            {/* <strong>{value.author_nameC6}</strong> */}
                          </span>
                        </p>
                        <p className={classes.p}>
                          <Editor
                            apiKey={apiKey}
                            id="titles2"
                            className="editor-input descript  task"
                            // className="editorss"
                            value={value.bodyC6}
                            init={{
                              menubar: true,
                              inline: true,
                              font_family_formats: fontStyles,
                              plugins: 'fontfamily',
                            }}
                            onEditorChange={async (content, editor) => {
                              setValue({ ...value, bodyC6: content });
                              await runExpandWords(1000);
                            }}
                          />
                          {data?.parent_template_name === '2-ICYMI-CARTOON' ||
                            (data?.parent_template_name === '2-COL-ICYMI-CART' && (
                              <>
                                <div style={{ width: 270, height: 320 }}>
                                  <Divider sx={{ borderColor: 'black' }} />
                                  <img height={348} width={270} src={images.Empty_Space_Cartoon} />
                                </div>
                              </>
                            ))}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                <div
                  className="second_col"
                  id="second_col"
                  style={{
                    backgroundColor:
                      value.color === '#e0a600'
                        ? '#fbf4e8'
                        : value.color === '#a0251c'
                          ? '#f6eae3'
                          : value.color === '#22553f'
                            ? '#e9e9ea'
                            : value.color === '#001948'
                              ? '#fff'
                              : '',
                  }}
                >
                  <div className="auth-details">
                    <div ref={secondCol}>
                      <h3 className={classes.h3}>
                        <Editor
                          apiKey={apiKey}
                          id="editortitleC7"
                          value={value.titleC7}
                          init={{
                            menubar: true,
                            inline: true,
                            content_style: `#editortitleC7 { color: ${value && value.color}; }`,
                            font_family_formats: getFontStyles(
                              'Adobe Garamond Pro=AGaramond-Regular;',
                              'Korolev Condensed Bold=korolev-condensed;'
                            ),
                            plugins: 'fontfamily',
                          }}
                          onEditorChange={(content, editor) => {
                            setValue({ ...value, titleC7: content });
                          }}
                        />
                      </h3>
                      <p
                        style={{
                          display:
                            !data?.versiondetail?.c7?.author_img ||
                            !data?.versiondetail?.c7?.dimensions ||
                            data?.versiondetail?.c7?.dimensions?.width !== 269 ||
                            data?.versiondetail?.c7?.dimensions?.height !== 270 ||
                            !data?.versiondetail?.c7.author_name
                              ? 'none'
                              : 'block',
                        }}
                        className={`${classes.p} auth-img`}
                      >
                        <span
                          style={{
                            width: '69px',
                            height: '70px',
                            display: 'block',
                          }}
                        >
                          <img src={data?.versiondetail?.c7?.author_img} alt="" />
                        </span>
                        <span
                          style={{
                            color:
                              value.color === '#e0a600'
                                ? '#e0a600'
                                : value.color === '#a0251c'
                                  ? '#a0251c'
                                  : '',
                          }}
                        >
                          <Editor
                            apiKey={apiKey}
                            id="author_nameC7"
                            value={value?.author_nameC7}
                            init={{
                              menubar: true,
                              inline: true,
                              content_style: `#author_nameC7 p {font-size: 12px !important; color: ${value && value.color};}`,
                              font_family_formats: getFontStyles(
                                'Adobe Garamond Pro=AGaramond-Regular;',
                                'Korolev Condensed Bold=korolev-condensed;'
                              ),
                              plugins: 'fontfamily',
                            }}
                            onEditorChange={(content, editor) => {
                              setValue({ ...value, author_nameC7: content });
                            }}
                          />
                            {(value.photo_caption_c7) && (<><span style={{ color: data && data.color }} className='photo-credit'>Image:</span>
                              <Editor
                                apiKey={apiKey}
                                id="caption_c7"
                                value={value.photo_caption_c7}
                                init={{
                                  menubar: true,
                                  inline: true,
                                  content_style: `#caption_c7 p {font-size: 12px !important;font-family:'korolev-condensed';color: ${data && data.color
                                    }; }`,
                                  font_family_formats: getFontStyles(
                                    'Adobe Garamond Pro=AGaramond-Regular;',
                                    'Korolev Condensed Bold=korolev-condensed;'
                                  ),
                                  plugins: 'fontfamily',
                                }}
                                onEditorChange={async (content, editor) => {
                                  setValue({ ...value, photo_caption_c7: content });
                                  await runExpandWords(1000);
                                }}
                              />
                            </>
                            )}
                          {/* <strong>{value?.author_nameC7}</strong> */}
                        </span>
                      </p>
                      <p className={classes.p}>
                        <Editor
                          apiKey={apiKey}
                          id="bodyC7"
                          value={value?.bodyC7}
                          init={{
                            menubar: true,
                            inline: true,
                            font_family_formats: fontStyles,
                            plugins: 'fontfamily',
                          }}
                          onEditorChange={async (content, editor) => {
                            setValue({ ...value, bodyC7: content });
                            await runExpandWords(1000);
                          }}
                        />
                      </p>

                      <div className="second_para-below">
                        <h3 className={classes.h3}>
                          <Editor
                            apiKey={apiKey}
                            id="titleC8"
                            value={value.titleC8}
                            init={{
                              menubar: true,
                              inline: true,
                              content_style: `#titleC8 { color: ${value && value.color}; }`,
                              font_family_formats: getFontStyles(
                                'Adobe Garamond Pro=AGaramond-Regular;',
                                'Korolev Condensed Bold=korolev-condensed;'
                              ),
                              plugins: 'fontfamily',
                            }}
                            onEditorChange={(content, editor) => {
                              setValue({ ...value, titleC8: content });
                            }}
                          />
                        </h3>
                        <p
                          style={{
                            display:
                              !data?.versiondetail?.c8?.author_img ||
                              !data?.versiondetail?.c8?.dimensions ||
                              data?.versiondetail?.c8?.dimensions?.width !== 269 ||
                              data?.versiondetail?.c8?.dimensions?.height !== 270 ||
                              !data?.versiondetail?.c8.author_name
                                ? 'none'
                                : 'block',
                          }}
                          className={`${classes.p} auth-img`}
                        >
                          <span
                            style={{
                              width: '69px',
                              height: '70px',
                              display: 'block',
                            }}
                          >
                            <img src={data?.versiondetail?.c8?.author_img} alt="" />
                          </span>
                          <span
                            style={{
                              color:
                                value.color === '#e0a600'
                                  ? '#e0a600'
                                  : value.color === '#a0251c'
                                    ? '#a0251c'
                                    : '',
                            }}
                          >
                            <Editor
                              apiKey={apiKey}
                              id="author_nameC8"
                              value={value?.author_nameC8}
                              init={{
                                menubar: true,
                                inline: true,
                                content_style: `#author_nameC8 p {font-size: 12px !important; color: ${value && value.color};}`,
                                font_family_formats: getFontStyles(
                                  'Adobe Garamond Pro=AGaramond-Regular;',
                                  'Korolev Condensed Bold=korolev-condensed;'
                                ),
                                plugins: 'fontfamily',
                              }}
                              onEditorChange={(content, editor) => {
                                setValue({ ...value, author_nameC8: content });
                              }}
                            />
                            {(value.photo_caption_c8) && (<><span style={{ color: data && data.color }} className='photo-credit'>Image:</span>
                              <Editor
                                apiKey={apiKey}
                                id="caption_c8"
                                value={value.photo_caption_c8}
                                init={{
                                  menubar: true,
                                  inline: true,
                                  content_style: `#caption_c8 p {font-size: 12px !important;font-family:'korolev-condensed';color: ${data && data.color
                                    }; }`,
                                  font_family_formats: getFontStyles(
                                    'Adobe Garamond Pro=AGaramond-Regular;',
                                    'Korolev Condensed Bold=korolev-condensed;'
                                  ),
                                  plugins: 'fontfamily',
                                }}
                                onEditorChange={async (content, editor) => {
                                  setValue({ ...value, photo_caption_c8: content });
                                  await runExpandWords(1000);

                                }}
                              />
                            </>
                            )}
                            {/* <strong>{value?.author_nameC8}</strong> */}
                          </span>
                        </p>
                        <p className={classes.p}>
                          <Editor
                            apiKey={apiKey}
                            id="bodyC8"
                            className="editor-input descript  task"
                            // className="editorss"
                            value={value.bodyC8}
                            init={{
                              menubar: true,
                              inline: true,
                              font_family_formats: fontStyles,
                              plugins: 'fontfamily',
                            }}
                            onEditorChange={async (content, editor) => {
                              setValue({ ...value, bodyC8: content });
                              await runExpandWords(1000);
                            }}
                          />
                          <span
                            style={{
                              width: '15px',
                              height: '15px',
                              display: 'inline-block',
                            }}
                          >
                            {data && (
                              <img src={images.PW_FAV_ICON} style={{ width: 15, height: 15 }} />
                            )}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              
              </div>
            </form>
          )}
        </div>
        <div className="col-4" style={{ display: 'none' }}>
          <div className="poll-container">
            <div className="barcode-box4" />
          </div>
          <div className="below-block">
            <div className="auth-details">
              <div className="auth-img" />
              <div className="auth-name" />
            </div>
          </div>
        </div>
      </div>
      <div className="footer-img">
        <div className="footer-bar" />
      </div>
      <div className="ad-container"></div>
      <Loading isLoading={isLoading} height={80} width={80} color="#15223F" />
      <Loading isLoading={isLoading1} height={80} width={80} color="#15223F" />
      <Message snackbar={snackbar} handleCloseSnackbar={handleCloseSnackbar} />
    </MainCard>
  );
};

export default ICYMI_II_cartoon;
