const STAGING = "prod"; // 'dev', 'prod', 'preview'

const KEY = {
  local: "ue1v5in9bc02qtk34dc0co760blssmnp1dlk4jvp1v4p1h0g",
  dev: "ue1v5in9bc02qtk34dc0co760blssmnp1dlk4jvp1v4p1h0g",
  prod: "eh66fsk4vah3pk595acr6q41ou5i8l05sad5u0n6ohjljhg7",
  preview: "ep8m2c4q5mr5p0oofwlf0mojes8wipepostl11byui0rhgdj",
};

export const Keys = {
    EditorKey: KEY[STAGING] || ""
}